import React from 'react';

const HeroIllustration = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 480 480'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M75.646 171.181c-19.524 6.395-31.25-4.889-30.805-12.772.742-13.151-10.833-20.702-17.528-18.948-24.687 6.468-19.191 41.256-15.015 53.667 8.623 25.623 22.261 35.395 30.33 41.312 18.677 13.694 48.096 9.704 62.726-9.373 9.642-12.573 15.103-34.804.56-48.034-8.083-7.353-19.457-7.24-24.471-7.113' />
				<path d='M85.054 193.079c-3.005 1.098-8.639 6.898-3.056 9.439 2.895 1.318 8.395.235 11.076-1.159 4.067-2.114 6.308-6.475 4.502-9.198-1.173-1.769-3.356-1.967-6.58-1.139' />
				<path d='M66.37 151.557c4.863 8.885 9.513 20.12 13.421 29.464 1.275 3.05 5.202 12.02 9.562 21.634m12.603 26.314c1.11 1.79 1.949 3.336 5.071.865 2.52-1.995 2.067-3.427-.207-6.791m-11.562-23.174c-3.961-7.674-19.935-42.812-21.814-49.671' />
				<path d='M64.539 127.096s-4.165 8.394-4.167 12.543c-.001 2.136-1.064 10.231 5.998 11.918 1.655.395 5.931-.23 7.074-1.359 5.752-5.685-.282-11.311-2.077-12.7-2.948-2.281-5.484-.233-6.828-10.402zm-44.468 46.756c3.731.786 10.067-8.508 3.363-10.551-6.824-2.08-10.894 8.964-3.363 10.551zm5.518 17.085c6.264-1.776 3.586-11.549-2.691-9.769-5.369 1.522-6.811 12.463 2.691 9.769zm5.02 8.094c-6.501 4.619.318 13.331 6.589 8.66 5.754-4.286-3.209-11.062-6.589-8.66zm15.002 15.085c-5.426 1.421-4.835 10.585 1.116 9.947 6.899-.741 6.419-11.922-1.116-9.947zm14.228 7.168c-4.391 8.022 4.858 10.682 8.095 7.204 6.104-6.558-5.817-11.365-8.095-7.204zm22.739-4.15c-6.864 7.27.55 14.312 7.008 7.471 3.772-3.995-2.732-12.001-7.008-7.471zM226.651 87.822c5.71 2.404 10.47 2.591 16.373.167m-17.608-7.486c5.71 2.403 12.269 2.456 18.171.032' />
				<path d='M243.684 79.613s-.194-4.254 2.159-10.455c1.864-4.913 6.971-10.73 5.158-18.959-4.061-18.441-29.751-13.909-33.134-.747-1.946 7.57-.999 13.564 3.288 18.752 2.242 2.712 4.016 9.507 4.081 10.971l1.507 11.114c.783 5.772.648 6.196 8.699 6.302 8.689.114 7.243-3.313 7.491-5.73.25-2.424.319-7.508.751-11.248z' />
				<path d='M231.446 82.13c.864-6.448-3.684-15.701-3.684-15.701 6.406 1.41 12.03.445 13.385-.145 0 0-3.908 9.991-3.13 15.786m-3.562-53.086l-.447-14.11m-25.419 35.394l-13.434-5.061m65.677 5.061l11.994-6.932m-55.924-9.995l-6.759-8.148m41.236 8.148l4.816-10.372M433.069 130.543c3.454 5.296-3.791 36.786-10.522 53.776-28.385 71.653-58.265 65.779-28.048-10.497 7.503-18.94 23.199-46.314 28.111-47.125' />
				<path d='M443.123 220.454c-9.321-2.679-30.81-19.582-43.847-32.187-65.604-63.428-32.79-72.991 19.025-22.894 9.074 8.772 29.06 34.524 32.648 45.902' />
				<path d='M355.355 182.615c2.307-4.224 31.038-14.561 51.529-19.104 83.23-18.454 86.696 10.633 5.456 28.646-8.94 1.982-41.144 6.321-53.14 2.09' />
				<path d='M405.367 183.612c-6.488-4.534-1.016-17.442 8.034-11.118 5.102 3.566-.116 16.652-8.034 11.118zm17.592-57.581c3.52-7.083 13.429-2.218 9.87 4.944-3.026 6.09-12.804.961-9.87-4.944zm27.882 85.181c6.308 3.786.381 12.877-5.211 10.697-9.1-3.548-1.907-14.97 5.211-10.697zm-97.149-28.101c7.015-3.111 12.037 7.594 5.508 11.136-7.362 3.993-14.348-7.217-5.508-11.136z' />
				<g>
					<path d='M186.269 266.881c22.696-13.719 17.232-40.463 16.489-47.727m77.219 48.64c-20.932-17.388-16.351-44.4-15.973-48.64' />
					<path d='M199.053 208.936c6.316 23.456 23.825 37.209 34.338 36.613 7.422-.42 30.901-12.981 34.345-39.821.299-2.335 3.819-22.617 3.713-26.055-.278-9.006-5.259-25.33-9.202-27.897-4.711-3.067-12.566 0-26.48 0-10.661 0-17.767-1.542-23.816 0-14.208 3.62-14.638 12.887-15.313 27.897-.499 11.093 1.211 24.79 2.415 29.263z' />
					<path d='M196.665 189.021c-1.775-8.581-8.433-32.799-3.057-40.405 21.022-29.748 49.091-22.179 54.409-21.923 5.562.268 10.447-1.256 11.701-1.717 14.03-5.148 25.742 15.496 17.552 28.032-3.522 5.39-5.537 22.874-5.853 27.662M111.01 313.513c8.552-36.969 39.691-45.719 75.259-46.632 7.612-.195 30.306 12.497 44.561 11.849 19.673-.894 30.488-4.788 49.147-10.936 3.477-1.146 46.457-.194 62.839 18.112 7.512 8.395 9.002 12.753 9.874 23.882' />
					<path d='M72.081 316.639s24.144 137.598 26.196 147.73c.526 2.599 270.274-2.179 271.606-3.955 2.385-3.18 24.669-147.94 21.199-149.912-3.487-1.98-302.54.127-319.001 6.137z' />
				</g>
				<g>
					<path d='M91.716 133.468c28.422-30.969 67.254-52.622 111.089-59.671m61.679-.116c33.766 5.299 64.589 19.259 90.007 39.473m69.496 124.249a184.647 184.647 0 011.175 20.827c0 35.191-9.967 68.124-27.289 96.244a187.606 187.606 0 01-13.548 19.182m-302.663-2.568a187.253 187.253 0 01-11.519-16.614c-17.322-28.12-27.29-61.053-27.29-96.244' />
				</g>
			</g>
		</svg>
	);
};

export default HeroIllustration;
