import React from 'react';

const ResearchIcon = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 108 91'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M9.072 5.743c.633-3.417 45.47 9.565 44.796 14.312-.179 1.267.837-5.275 11.344-9.61C76.249 5.892 97.63-.619 98.224 2.173c2.327 10.928 2.327 72.989.958 74.186-.775.678-11.167.879-20.555 2.959-10.822 2.398-22.088 6.71-24.759 7.944 0 0-10.344-5.467-21.244-7.944-9.601-2.181-19.904.237-20.15 0-.356-.344-1.125-13.548-1.335-18.263-.564-12.631-1.321-25.208-1.694-37.857-.175-5.948-.942-11.476-.373-17.455zm44.796 14.45c1.236 13.706-.341 59.23 0 67.069' />
				<path d='M8.782 10.535c-1.154.123-5.903-.227-6.763.68-2.225 2.343 3.318 72.013 5.068 73.225 1.568 1.086 13.246-2.983 24.327-.852 8.823 1.697 21.027 5.912 22.294 5.912 2.119 0 17.958-4.6 24.55-5.638 7.3-1.15 25.559-1.284 26.262-3.022 1.76-4.351 1.76-73.701 0-73.701-2.232 0-4.26-.54-5.642 0' />
			</g>
		</svg>
	);
};

export default ResearchIcon;
