import React from 'react';
import styled from '@emotion/styled';

import ButtonLink from '../shared/ButtonLink';

import respond from '../../styles/mediaQueries';

const StyledContactSection = styled('section')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: ${props =>
		props.noBorder ? null : '.3rem solid var(--text2)'};
    padding-top: 16rem;
    margin-bottom: 16rem;

    ${respond('bp4')} {
        padding-top: 8rem;
        margin-bottom: 8rem;
    }

    & h4 {
        margin-bottom: 4rem;

        ${respond('bp4')} {
            text-align: center;
        }
    }
`;

const Contact = ({ title, linkText, noBorder }) => {
	return (
		<StyledContactSection noBorder={noBorder}>
			<h4>{title}</h4>
			<ButtonLink to='/contact'>{linkText}</ButtonLink>
		</StyledContactSection>
	);
};

export default Contact;
