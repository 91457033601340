import React from 'react';

const PotwinIllustration = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeMiterlimit='100'
			clipRule='evenodd'
			viewBox='0 0 270 270'>
			<g fill='none' stroke={fill} strokeWidth='2.5'>
				<path d='M198.013 56.356c0 8.129 2.709 25.083 6.878 46.253 4.285 21.757 10.119 39.258 10.823 40.546 1.862 3.401 40.227-2.45 39.428-6.393-.71-3.501-1.109-22.652-3.897-39.098-2.728-16.096-10.511-42.387-10.961-45.937-.675-5.311-42.271-1.232-42.271 4.629z' />
				<path d='M207.56 114.91c-1.662.053-35.344 3.957-72.067 11.051-85.965 16.607-115.974 27.627-125.908 25.98-3.146-.522-8.437-24.546-4.051-27.588 4.305-2.985 30.94-2.508 125.156-19.821 34.252-6.294 66.987-17.276 71.07-18.615' />
			</g>
			<path
				fill='none'
				stroke={fill}
				strokeLinejoin='round'
				strokeMiterlimit='1.5'
				strokeWidth='2.5'
				d='M221.415 183.096c1.839-2.213 3.043-3.097 5.648-4.933m37.589-11.049h-8.094m-2.59 10.289a18.352 18.352 0 001.938 1.502c.73.494 1.466.928 2.394 1.53.373.242-.449-.311 0 0m-1.742-28.331c-.786.705-3.112 3.602-3.893 4.611-.314.407.4-.478 0 0m-32.418 14.082c-1.055.052-5.275-.291-6.517 0m10.424-10.035c-2.976-2.138-2.318-1.888-5.479-3.936'
			/>
			<g
				fill='none'
				stroke={fill}
				strokeLinejoin='round'
				strokeMiterlimit='1.5'
				strokeWidth='2.5'>
				<path d='M235.896 172.808c-2.083-.023-3.897.014-5.036.014-1.332 0-.742-5.13 0-5.13 2.19 0 15.142-.092 16.146-.092 1.528 0 1.792 5.222.403 5.222-1.086 0-3.079-.008-5.14-.014' />
				<path d='M235.926 172.812c.47 5.31.191 39.193-.159 42.898-.045.473 3.145 4.081 3.145 4.081s3.024-3.547 2.986-4.153c-.14-2.208.001-38.237.371-42.826' />
			</g>
		</svg>
	);
};

export default PotwinIllustration;
