import React from 'react';

const RepeatIcon = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 82 70'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M40.94 40.785c.33.341 9.431 12.389 19.45 12.366 14.124-.031 19.272-10.976 19.272-18.466 0-5.755-3.731-18.483-19.272-18.492-14.106-.008-27.783 28.134-39.456 28.127-6.795-.004-12.341-4.424-12.015-9.683.296-4.767 3.38-9.671 12.015-9.665 5.379.004 12.534 8.522 14.141 10.197' />
				<path d='M46.308 34.778c4.888 5.167 8.147 9.565 14.082 9.569 9.531.006 11.847-6.625 11.847-9.651 0-3.291-1.421-9.69-11.847-9.697-11.869-.008-22.71 28.137-39.456 28.154-12.015.013-19.285-8.847-19.272-18.506.013-9.676 7.244-18.488 19.272-18.48 9.685.007 18.621 11.895 19.704 13.149' />
			</g>
		</svg>
	);
};

export default RepeatIcon;
