import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';

import SectionHeading from '../shared/SectionHeading';
import RecentWork from '../LandingPage/RecentWork';
import SectionDark from '../shared/SectionDark';
import SectionLight from '../shared/SectionLight';

import timestampData from '../../data/timestamp';
import rankerData from '../../data/ranker';
import mmaData from '../../data/mma';
import vsainteData from '../../data/vsainte';
import potwinData from '../../data/potwin';
import rexfordData from '../../data/rexford';

import RankerIllustration from '../svg/landing-illustrations/RankerIllustration';
import MMAIllustration from '../svg/landing-illustrations/MMAIllustration';
import VsainteIllustration from '../svg/landing-illustrations/VsainteIllustration';
import PotwinIllustration from '../svg/landing-illustrations/PotwinIllustration';
import RexfordIllustration from '../svg/landing-illustrations/RexfordIllustration';
import TimestampIllustration from '../svg/landing-illustrations/TimestampIllustration';

import respond from '../../styles/mediaQueries';

const StyledRecentWorkSection = styled('section')`
    margin-top: 16rem;

	${respond('bp4')} {
		margin-top: 12rem;
	}
`;

const RecentWorkSection = () => {
	const imageData = useStaticQuery(imageQuery);

	return (
		<StyledRecentWorkSection>
			<SectionLight>
				<SectionHeading color={'var(--text2)'} reverse>
					Recent Work
				</SectionHeading>
				<RecentWork data={timestampData} image={imageData.timestamp}>
					<TimestampIllustration fill={'var(--text2)'} />
				</RecentWork>
			</SectionLight>
			<SectionDark height='16rem' />
			<SectionLight>
				<RecentWork data={rankerData} image={imageData.ranker}>
					<RankerIllustration fill={'var(--text2)'} />
				</RecentWork>
			</SectionLight>
			<SectionDark height='16rem' />
			<SectionLight>
				<RecentWork data={mmaData} image={imageData.css}>
					<MMAIllustration fill={'var(--text2)'} />
				</RecentWork>
			</SectionLight>
			<SectionDark height='16rem' />
			<SectionLight>
				<RecentWork data={vsainteData} image={imageData.vsainte}>
					<VsainteIllustration fill={'var(--text2)'} />
				</RecentWork>
			</SectionLight>
			<SectionDark height='16rem' />
			<SectionLight>
				<RecentWork data={potwinData} image={imageData.potwin}>
					<PotwinIllustration fill={'var(--text2)'} />
				</RecentWork>
			</SectionLight>
			<SectionDark height='16rem' />
			<SectionLight>
				<RecentWork data={rexfordData} image={imageData.rexford}>
					<RexfordIllustration fill={'var(--text2)'} />
				</RecentWork>
			</SectionLight>
			<SectionDark height='16rem' />
		</StyledRecentWorkSection>
	);
};

export default RecentWorkSection;

const imageQuery = graphql`
	query RecentWork {
		timestamp: file(
			relativePath: { eq: "case-study/timestamp/timestamp-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid
				}
			}
		}
		css: file(relativePath: { eq: "case-study/mma/mma-multi.png" }) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid
				}
			}
		}
		potwin: file(
			relativePath: { eq: "case-study/potwin/potwin-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid
				}
			}
		}
		ranker: file(
			relativePath: { eq: "case-study/ranker/ranker-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid
				}
			}
		}
		rexford: file(
			relativePath: { eq: "case-study/rexford/rexford-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid
				}
			}
		}
		vsainte: file(
			relativePath: { eq: "case-study/vsainte/vsainte-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
