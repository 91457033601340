import React from 'react';

const DevelopIcon = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 107 91'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M4.431 2.188C3.115 2.188 1.5 23.207 1.5 34.863c0 10.899 0 32.193 1 32.815 1.657 1.033 25.79 3.26 50.293 2.835 24.445-.425 50.796-1.906 51.369-2.835.554-.898 1.404-16.709 1.334-32.815-.073-16.814-1.821-32.391-2.581-32.675-4.133-1.548-91.181 0-98.484 0z' />
				<path d='M42.87 23.688C38.677 26.161 27.063 33.835 27.102 34c.268 1.107 11.669 6.168 15.768 8.363m6.612.982l9.694-19.657m5.274 0c2.14 1.131 13.118 6.181 15.388 8.134 1.003.862-10.981 8.453-15.388 10.541M1.684 57.816c.47.009 25.565 2.559 51.109 2.572 25.826.013 51.921-2.511 52.231-2.572M31.243 83.021c-1.374.417-6.918 4.546-6.118 6.245.235.499 54.235.196 56.266-.869 1.708-.895-5.13-5.655-7.801-5.88-5.443-.457-39.191-.455-42.347.504zm5.629-.488c4.374-2.866 6.47-11.947 6.478-11.967m25.401 11.763c-2.153-2.165-6.068-9.616-6.157-12.038' />
			</g>
		</svg>
	);
};

export default DevelopIcon;
