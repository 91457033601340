import React from 'react';
import Layout from '../components/Layout/Layout';

import SEO from '../components/Layout/SEO';
import HeroSection from '../components/LandingPage/HeroSection';
import RecentWorkSection from '../components/LandingPage/RecentWorkSection';
import ProcessSection from '../components/LandingPage/ProcessSection';
import ContactSection from '../components/LandingPage/ContactSection';

const Index = ({ location }) => {
	return (
		<Layout location={location}>
			<SEO title='Home' />
			<HeroSection />
			<RecentWorkSection />
			<ProcessSection />
			<ContactSection />
		</Layout>
	);
};

export default Index;
