import React from 'react';

const VsainteIllustration = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 360 360'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M56.911 205.607c.462 3.838 4.477 7.003 21.237 4.289 6.07-.982 12.701-3.837 12.701-3.837s.752-3.908.851-4.765m-.851 4.765c52.979 10.287 72.317 5.648 122.695 7.048m41.557-32.428c-1.435-5.932-4.815-20.956 3.713-32.83 4.547-6.331 31.551-6.331 34.965 2.344 6.319 16.06.654 31.543-3.01 37.58-6.429 10.593-32.295 6.841-35.668-7.094z' />
				<path d='M91.7 201.294c.636-5.493.474-6.158 1.117-11.702 0 0-8.218-9.333-10.668-9.333-2.693 0-31.47 16.758-31.47 18.882 0 2.123 5.394 7.353 18.722 7.353 10.439 0 15.505-2.864 22.299-5.2zm157.649-24.271c-1.713-3.218-10.527-14.792-22.004-20.017-11.872-5.406-29.904-6.142-37.677-3.431-16.231 5.662-28.889 10.137-41.827 11.667-7.882.932-14.91-4.193-19.653-6.263-25.06-10.934-38.722 13.887-44.828 13.887-3.148 0-10.501-13.634-25.241-23.176-13.847-8.962-31.241-10.219-30.396-12.03 1.221-2.615 40.144-19.997 46.577-22.655 29.018-11.997 48.243 3.507 59.41 5.306 36.66 5.908 61.502-14.09 86.194 3.18 12.518 8.755 28.131 29.988 33.909 50.809' />
				<path d='M92.817 189.592c5.468 3.22 58.949-2.343 102.046-8.271 17.069-2.348 30.882-3.675 40.867-4.317 15.244-.978 18.29.576 19.271 3.255m-28.516 6.146c-1.55-1.892-2.956-6.063-1.55-8.559M91.7 201.294c15.741 3.607 91.96 11.571 106.316 11.52m26.919.275c3.954.36 9.355.018 15.194.018 6.463 0 41.32-9.367 47.459-11.813 0 0 7.837-2.292 20.633-3.427 4.384-.389 7.397-1.501 12.255-1.575 4.811-.072 12.69 0 20.689 0 4.478 0 6.062 7.618 13.207 7.618 3.255 0 6.389-12.381 1.923-15.014-2.941-1.732-5.212-2.53-37.399-3.201-20.774-.434-22.073.53-27.329.644m14.272-.733c-1.161 2.706-.443 10.405.166 12.474' />
				<path d='M249.843 191.944c-4.837 2.519-15.228 5.94-21.431 8.359-3.74 1.459-6.352 3.31-6.022 5.228.621 3.601 9.573 26.713 11.205 28.874 1.122 1.484 4.452 3.581 5.467 4.767 1.03 1.205 2.837 4.304 2.837 7.502 0 3.465-9.64 4.086-12.023 0-2.514-4.31-18.72-37.839-21.156-44.66-3.401-9.522 33.205-21.901 36.807-23.094 10.989-3.642 13.883 8.042 4.316 13.024zm-15.467-8.924c-1.73 1.455 3.134 14.063 5.162 13.179' />
				<path d='M228.877 200.121c-1.255.583.824 4.639 2.638 3.875 13.124-5.533 27.872-8.579 26.616-17.591m-26.474 17.529c.47 3.26 5.724 9.062 8.467 9.173m29.456-20.16c-.757 3.953-21.675 18.506-29.451 20.16m12.402-2.195c8.005-2.413 22.382-14.368 22.924-17.076m-127.034 32.793c-14.114 5.061-57.943 21.501-65.618 22.881m7.882-27.552c-12.075 3.874-47.782 12.531-55.993 13.41m18.608-55.3c-6.758 0-25.825-1.431-36.079-1.431m21.438 29.171c-14.008 1.635-26.151 1.635-37.171 4.338' />
			</g>
		</svg>
	);
};

export default VsainteIllustration;
