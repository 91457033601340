import React from 'react';
import styled from '@emotion/styled';
import Img from 'gatsby-image';

import Illustration from './Illustration';
import HeadingArrow from '../shared/HeadingArrow';
import TextBlock from '../shared/TextBlock';

import respond from '../../styles/mediaQueries';

const StyledRecentWork = styled('article')`

`;

const Content = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-right: .3rem solid var(--text2);

    ${respond('bp4')} {
        height: auto;
        border: none;
    }
`;

const Text = styled('div')`
    margin-bottom: 12rem;

    ${respond('bp2')} {
        min-height: 0;
        margin-bottom: 8rem;
        border-right: none;
    }

    ${respond('bp4')} {
        height: auto;
        min-height: 0;
        margin-bottom: 6.4rem;

    }

    & a {
        display: inline-block;
        margin-top: 2.8rem;
        margin-right: 2.4rem;
    }

`;

const MetaDate = styled('small')`
    position: absolute;
    right: -2.2rem;
    bottom: 0;
    padding: 1.6rem .8rem 14.4rem .8rem;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background-color: var(--bgPrimary);
    font-family: var(--fontHeader);
    color: var(--text2);
    letter-spacing: .1rem;

    ${respond('bp1')} {
        padding: 1.6rem .8rem 12rem .8rem;
    }

    ${respond('bp3')} {
        padding: 1.6rem .8rem 8rem .8rem;
    }

    ${respond('bp4')} {
        display: none;
    }
`;

const ImgContainer = styled('div')`
    display: flex;
    justify-content: center; 
    width: 100%;
    max-width: 100rem;
    margin: 0 auto -12rem auto;
    overflow: hidden;
    z-index: 1;

    ${respond('bp1')} {
        max-width: 80rem;
        margin: 0 auto -12rem auto;
    }

    ${respond('bp3')} {
          max-width: 64rem;
        margin: 0 auto -10.4rem auto;
    }

    ${respond('bp4')} {
         margin: 0 auto -6.4rem auto;
    }
    
    & div.gatsby-image-wrapper {
        width: 100%;
    }

    & img {
        width: 100%;
        height: auto;
    }
`;

const RecentWork = ({ data, children, image }) => {
	const { main } = data.images;
	const { name, date, description, color, demoLink, csLink } = data;

	return (
		<StyledRecentWork>
			<Illustration>{children}</Illustration>
			<Content>
				<Text>
					<TextBlock>
						<HeadingArrow color={color}>{name}</HeadingArrow>
						<p>{description}</p>
					</TextBlock>
					<MetaDate>{date}</MetaDate>
					<a href={csLink.link}>{csLink.text}</a>
					<a href={demoLink.link}>{demoLink.text}</a>
				</Text>
				<ImgContainer>
					<Img fluid={image.childImageSharp.fluid} alt={main.alt} />
				</ImgContainer>
			</Content>
		</StyledRecentWork>
	);
};

export default RecentWork;
