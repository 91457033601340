import React from 'react';
import styled from '@emotion/styled';

import respond from '../../styles/mediaQueries';

const StyledProcess = styled('div')`
    margin-top: 4rem;
    display: flex;


    ${respond('bp3')} {
        flex-direction: column;    
    }

    ${respond('bp4')} {
        margin-top: 3.2rem;
    }
`;

const ProcessText = styled('div')`
    flex: 0 1 64rem;

    ${respond('bp3')} {
        flex-basis: auto;   
    }

    & p {
        margin-bottom: 0;
    }
`;

const ProcessIcon = styled('div')`
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ${respond('bp3')} {
        justify-content: center;
    }


    & svg {
        height: 7.2rem;
        margin-left: 2.4rem;

        ${respond('bp3')} {
            margin-top: 2.4rem;
            margin-left: 0;
        }

        ${respond('bp4')} {
            height: 6.4rem;
        }
    }
`;

const Process = ({ data, children }) => {
	const { title, description } = data;

	return (
		<StyledProcess>
			<ProcessText>
				<h4>{title}</h4>
				<p>{description}</p>
			</ProcessText>
			<ProcessIcon>{children}</ProcessIcon>
		</StyledProcess>
	);
};

export default Process;
