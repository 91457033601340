import React from 'react';

const RepeatIcon = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 91 91'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M44.444 1.5C26.214 1.5 1.5 16.935 1.5 45.213 1.5 73.49 26.243 89.5 44.444 89.5 62.223 89.5 89.5 73.356 89.5 43.792 89.5 16.876 62.554 1.5 44.444 1.5zm0 0c-.05 2.99.228 74.559 0 88' />
				<path d='M44.444 1.5C21.059 16.876 20.376 41.201 20.376 45.626c0 15.278 2.057 27.73 24.068 43.874m0-88c23.653 15.376 24.069 39.575 24.069 44 0 15.277-1.805 27.856-24.069 44M5.919 26.498c4.309 0 76.281.031 79.437-.004' />
				<path d='M1.5 45.507c3.486.055 44.589.137 69.603.096 9.185-.015 16.201-.047 18.388-.103M5.919 64.616h78.412' />
			</g>
		</svg>
	);
};

export default RepeatIcon;
