import React from 'react';

const RankerIllustration = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 360 360'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M54.137 156.507c-4.233 8.234-4.319 23.571-4.319 23.571m258.293-3.91c-.038 11.781-.717 15.99-3.924 26.775m20.485-13.311c.203 5.882-1.825 14.194-3.242 16.397' />
				<path d='M197.252 302.18s48.195-16.303 78.687-39.17c25.217-18.911 47.103-40.622 47.103-40.622l-38.392-7.62c.268-3.597 8.929-31.328 3.015-72.33-4.122-28.581-10.515-36.385-10.515-36.385l-82.495-17.854c9.773 25.77 24.379 87.567 18.554 111.148l-46.525-10.577s1.406 25 6.984 46.114c7.806 29.538 23.584 67.296 23.584 67.296zM38.954 153.472c-2.001 3.328-3.636 8.035-3.64 13.289' />
				<path d='M161.616 57.82s-31.17 8.937-76.299 41.043c-25.815 18.366-46.351 39.101-46.351 39.101l35.84 7.374c-.266 3.596-9.517 31.222-3.603 72.224 4.121 28.581 10.514 36.386 10.514 36.386l79.306 17.163c-13.227-23.844-22.127-86.571-16.302-110.152l47.463 10.271s-5.937-32.958-13.539-57.788L161.616 57.82zm124.243 43.332c2.251 1.371 5.561 7.947 6.341 10.61M68.017 246.487c.661 3.902 2.034 7.281 4.829 10.632' />
			</g>
		</svg>
	);
};

export default RankerIllustration;
