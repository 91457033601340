import React from 'react';

const RexfordIllustration = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 360 360'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M101.131 254.218c-10.368 1.856-36.821 7.283-58.038 15.349-4.802 1.827-14.444-.995-20.994-16.015-4.193-9.611-5.838-23.168-5.971-30.521-.223-12.349 4.32-24.613 6.758-26.824 5.711-5.181 17.364-.848 35.811.994 13.262 1.326 26.31 2.631 32.848 2.382 24.995-.949 59.149-6.172 86.988-9.324 23.843-2.7 67.499-8.962 97.732-16.435 22.623-5.591 29.253-10.269 33.258-11.687 19.709-6.981 27.995 9.437 30.63 22.279 2.236 10.899 10.27 34.813-16.633 34.623-6.724-.048-35.825 3.194-36.511 3.307-29.596 4.863-71.323 11.548-99.133 16.525-38.644 6.917-77.058 13.613-86.745 15.347z' />
				<path d='M91.545 199.583c-.78 3.166-2.646 13.488-1.098 25.158 1.906 14.348 9.686 28.494 10.684 29.477m-48.688 12.057c-1.029-3.259 4.949-7.966 13.378-10.249 8.786-2.38 18.339-3.386 21.103 1.042m44.205-36.153c-1.876-.932-4.251.458-5.112 2.05-1.017 1.879-1.331 4.161 1.185 5.205 2.457 1.021 4.915.762 6.493-2.152.959-1.772.055-3.8-2.566-5.103zm109.407-16.899c-2.292-1.14-4.22-.078-5.208 1.746-.986 1.824.035 4.075 1.629 4.867 2.778 1.381 4.79 1 6.205-1.613 1.304-2.411-.951-4.168-2.626-5zm35.729-30.192c-.223.328-1.47 13.323 1.508 25.585 2.645 10.891 8.055 21.347 9.236 22.937M309.523 162.137c1.397-6.083 2.653-22.64 1.379-22.727-15.294-1.053-132.057-15.47-160.01-19.133-10.57 2.402-39.494 7.275-58.382 9.722-32.487 4.207-67.4 4.556-67.299 4.846 1.732 4.981 42.691 34.782 110.755 39.885 22.04 1.652 94.946 7.025 99.535 7.359' />
				<path d='M188.324 131.485s-2.1 4.161-10.086 3.43c-3.851-.353-20.735-2.222-23.674-3.169-4.972-1.602-4.2-4.664-4.2-4.664 6.388.822 28.698 3.211 37.96 4.403z' />
				<g>
					<path d='M257.798 114.034c3.039-5.276 7.245-10.41 12.019-15.177m-144.441 9.562c-3.266-6.598-5.734-11.956-8.962-18.688m207.57 43.385a46.343 46.343 0 005.299-3.124c1.826-1.243 3.521-2.556 5.783-4.161.911-.646-1.14.757 0 0M205.353 91.772a28.999 28.999 0 00-1.776 4.406c-.521 1.657-.921 3.328-1.532 5.437-.246.849.336-1.019 0 0M52.889 122.146c-7.303-5.246-5.688-4.633-13.445-9.659' />
				</g>
			</g>
		</svg>
	);
};

export default RexfordIllustration;
