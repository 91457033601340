import React from 'react';

import SectionLight from '../shared/SectionLight';
import Contact from '../../components/shared/Contact';

const ContactSection = () => {
	return (
		<SectionLight>
			<Contact
				title='Interested in Working Together?'
				linkText='Let Me Know'
				noBorder
			/>
		</SectionLight>
	);
};

export default ContactSection;
