export default {
	research: {
		title: 'Research',
		description:
			'I start all projects with research to map out the best ways to approach design and development. This helps uncover what works for similar projects and ways to create a unique success.',
		img: ''
	},
	design: {
		title: 'Design',
		description:
			'I design all major project components before coding to explore visual and usability decisions based on research notes. Modern design software also makes it easy to document and share development progress.',
		img: ''
	},
	develop: {
		title: 'Develop',
		description:
			'During development, I prioritize writing well organized code and using modern programming methods to create a high quality foundation. Every change is saved with version control software and backed up in a safe location.',
		img: ''
	},
	test: {
		title: 'Test',
		description:
			'After completing a development cycle, I test each project for speed and stability using a variety of tools. I write automated tests for key pieces of code to ensure each project is as bug free and high quality as possible.',
		img: ''
	},
	deploy: {
		title: 'Deploy',
		description:
			'During deployment, I use modern automation methods that make updating each project as fast as possible. I run through detailed checks before deploying to help minimize the risk of bugs reaching the end user.',
		img: ''
	},
	repeat: {
		title: 'Repeat',
		description:
			'Just because a project is built, doesn’t mean it’s finished. I use the same careful process listed above any time I make updates or edits to a project. I constantly work to refine my process to maximize efficiency and speed.',
		img: ''
	}
};
