import React from 'react';

const TestIcon = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 91 91'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M61.756 12.358c1.486.89 4.687-.931 5.78-2.478.575-.814-13.052-8.809-13.296-8.362-1.243 2.27-.453 6.071.537 6.654M50.229 39.96c-2.174 3.819-4.635 7.978-5.292 9.297-.597 1.199-5.746 3.329-5.746 3.329l-7.874-4.764c-.366-1.883.385-6.03.66-6.485 1.724-2.85 16.891-31.065 17.189-30.886 1.901 1.146 10.138 6.029 12.945 7.806.336.213-3.643 6.685-3.934 7.18' />
				<path d='M31.317 47.822l-2.226 3.903c-.119.209 7.821 5.05 7.989 4.741l2.111-3.88m20.143-36.041l2.348-4.118c.119-.209-6.74-4.548-6.908-4.239l-2.306 4.239m2.139 12.269c-6.245 0-7.933 4.315-7.933 8.503 0 3.784 1.688 7.514 7.163 7.514 6.301 0 7.646-3.734 7.646-7.514 0-5.526-1.926-8.503-6.876-8.503z' />
				<path d='M57.555 40.127c3.343 1.298 8.592 6.063 8.696 12.459.054 3.281-1.206 7.578-5.615 10.847-4.605 3.413-10.869 2.987-11.109 3.147' />
				<path d='M50.442 73.412c6.602 0 14.418-1.519 18.033-4.3 2.716-2.089 5.065-4.257 7.208-8.372 2.316-4.451 2.381-9.697 2.088-11.986-.421-3.282-2.44-9.025-5.961-12.482-2.515-2.47-11.168-9.122-13.633-10.835M38.245 66.582c-4.256.114-14.898.114-15.972 0-.993-.106-1.142 6.83-.184 6.83h15.34' />
				<path d='M37.393 89.431V72.456c0-3.842 0-8.599 6.293-8.599 6.756 0 6.756 4.757 6.756 8.599V89.4l-13.049.031zm0-7.483c-6.076 0-31.729.007-33.923.007 0 0-2.192 7.511-1.952 7.518 3.048.08 34.362-.042 35.875-.042m13.049-7.483h37.493c.2 0 1.835 7.445 1.526 7.452-2.89.07-33.893 0-39.019 0' />
			</g>
		</svg>
	);
};

export default TestIcon;
