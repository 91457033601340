import React from 'react';

const MMAIllustration = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 360 360'>
			<path
				fill='none'
				stroke={fill}
				strokeWidth='3'
				d='M135.372 82.39c-19.354 3.47-52.029 11.177-57.994 17.868-1.299 1.456-4.632 14.846-4.632 16.131 0 1.768 5.495-2.125 9.105-3.618 6.487-2.683 24.399-12.014 65.184-17.321 46.1-5.998 100.719-5.998 106.466-6.745 5.846-.761-22.393-15.11-27.321-16.823-1.837-.637-11.524 1.33-23.821 2.636-22.268 2.363-53.715 5.491-66.987 7.872z'
			/>
			<path
				fill='none'
				stroke={fill}
				strokeWidth='3'
				d='M79.12 98.761s1.488-3.439 1.905-4.555c4.198-11.252 8.012-14.608 53.951-22.447 35.785-6.106 35.802-5.9 57.839 3.763M55.099 193.99c-.609.546.881 24.302 2.563 32.658.819 4.062 1.164 8.895 9.528 14.104 10.78 6.713 19.816 10.686 21.028 10.686'
			/>
			<path
				fill='none'
				stroke={fill}
				strokeWidth='3'
				d='M74.564 116.389L66.81 141.82l-16.076 46.349c-1.391 4.011.136 5.723 8.014 6.205 12.222.748 27.601 8.81 27.657 10.17.131 3.149 1.893 52.895 2.439 59.007.556 6.233 21.792 9.409 29.667 9.409 6 0 27.221-.407 28.476-9.409 1.205-8.646.235-82.834.235-98.676'
			/>
			<path
				fill='none'
				stroke={fill}
				strokeWidth='3'
				d='M147.035 263.157c.529 9.966 17.645 15.365 34.715 14.298 16.397-1.024 26.093-4.008 27.146-8.051.981-3.761 1.216-100.637-3.462-111.065'
			/>
			<path
				fill='none'
				stroke={fill}
				strokeWidth='3'
				d='M209.413 253.428c2.931 5.896 13.259 9.034 25.871 8.027 14.972-1.195 25.309-7.656 25.706-8.026 1.909-1.783 1.909-82.225-11.683-99.353'
			/>
			<path
				fill='none'
				stroke={fill}
				strokeWidth='3'
				d='M225.403 89.724c7.596 3.055 30.622 13.899 39.106 19.049 19.393 11.773 28.454 23.378 36.7 59.161 5.777 25.07 5.698 59.16 4.99 59.824-3.44 3.232-14.581 9.022-18.795 9.913-9.383 1.983-19.001 4.178-25.515-.127m-.714 15.445c1.637 3.888 4.123 5.078 8.148 5.684 6.549.986 26.042-2.852 29.557-7.63 2.655-3.608 2.514-14.739 1.872-19.487m-90.16 23.657c0 11.181-1.179 21.672 14.767 22.375 4.756.21 19.014-.152 27.155-4.628 5.353-2.944 5.353-11.861 5.353-17.747m-107.323 14.78c0 18.366 6.886 22.375 29.456 22.375 13.045 0 25.238-3.083 25.238-19.408m-113.056-5.545c-.203 5.773.324 11.195 3.738 15.213 4.583 5.393 24.898 9.561 40.047 3.988 9.503-3.496 8.846-12.285 8.846-19.201l-.639.53M88.631 163.011c-2.89 20.814-2.034 41.533-2.034 41.533m-34.45-79.624c-5.804-2.22-7.439-2.737-9.225-3.222-1.685-.458-3.504-.887-9.087-2.689m3.159 51.827c-3.74-.262-13.071 1.121-17.52 2.854m22.699 62.607c-4.303.337-9.448.781-16.676 7.864m294.795-8.658c9.003 1.468 9.831 2.227 17.496 8.658m-17.847-72.398c6.484-1.158 13.253-1.311 20.585-.002m-46.695-60.673c6.767-.049 11.565-2.955 17.839-6.563'
			/>
		</svg>
	);
};

export default MMAIllustration;
