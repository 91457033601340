import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring';
import useMeasure from 'react-use-measure';

import Illustration from './Illustration';
import ProcessIllustration from '../svg/landing-illustrations/ProcessIllustration';
import ProcessNavigation from './ProcessNavigation';
import SectionHeading from '../shared/SectionHeading';
import Process from './Process';

import ResearchIcon from '../svg/icons/ResearchIcon';
import DesignIcon from '../svg/icons/DesignIcon';
import DevelopIcon from '../svg/icons/DevelopIcon';
import TestIcon from '../svg/icons/TestIcon';
import DeployIcon from '../svg/icons/DeployIcon';
import RepeatIcon from '../svg/icons/RepeatIcon';

import respond from '../../styles/mediaQueries';
import processData from '../../data/processes';
import SectionLight from '../shared/SectionLight';

const StyledProcessSection = styled('section')`
	display: grid;
	grid-template-columns: 20rem 1fr;
	grid-template-rows: min-content 1fr;
	color: var(--text);
	border: .3rem solid var(--text);
	border-radius: .2rem;
	margin-bottom: 2.4rem;



	${respond('bp3')} {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, min-content)
	}
`;

const ProcessHeading = styled('header')` 
	grid-column: 2 / 3;
	grid-row: 1 /2;
	padding: 4rem 4rem 0 4rem;

	${respond('bp3')} {
		grid-column: 1 /1;
		grid-row: 1 / 2;
		padding: 4rem 4rem 0 4rem;
	}

	${respond('bp4')} {
		padding: 3.2rem 2.4rem 0 2.4rem;
	}
`;

const ProcessContent = styled('article')`
	position: relative;
	grid-column: 2 / 3;
	margin: 1.6rem 4.8rem 4rem 4.8rem;
	min-height: ${props => props.height + 'px'};

	${respond('bp3')} {
		grid-column: 1 / 1;
		grid-row: 3 / 4;
		margin: 0 4rem 4rem 4rem;
	}

	${respond('bp4')} {
		margin: 0 2.4rem 3.2rem 2.4rem;
	}

	& p {
		color: var(--text2);
	}
`;

const ProcessSection = () => {
	const [ current, setCurrent ] = useState('Research');
	const [ ref, bounds ] = useMeasure();

	const { research, design, develop, test, deploy, repeat } = processData;
	const processes = {
		Research: {
			data: research,
			icon: <ResearchIcon fill={'var(--text2)'} />
		},
		Design: {
			data: design,
			icon: <DesignIcon fill={'var(--text2)'} />
		},
		Develop: {
			data: develop,
			icon: <DevelopIcon fill={'var(--text2)'} />
		},
		Test: {
			data: test,
			icon: <TestIcon fill={'var(--text2)'} />
		},
		Deploy: {
			data: deploy,
			icon: <DeployIcon fill={'var(--text2)'} />
		},
		Repeat: {
			data: repeat,
			icon: <RepeatIcon fill={'var(--text2)'} />
		}
	};

	const transitions = useTransition(current, null, {
		config: {
			mass: 1,
			tension: 100,
			friction: 20,
			velocity: -10
		},
		from: { position: 'absolute', opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 }
	});

	const processAnimation = transitions.map(({ item, key, props }) => {
		return (
			<animated.div style={props} key={key} ref={ref}>
				<Process data={processes[item].data}>
					{processes[item].icon}
				</Process>
			</animated.div>
		);
	});

	return (
		<SectionLight>
			<Illustration>
				<ProcessIllustration fill={'var(--text2)'} />
			</Illustration>
			<StyledProcessSection>
				<ProcessNavigation current={current} setCurrent={setCurrent} />
				<ProcessHeading>
					<SectionHeading reverse color={'var(--text2)'}>
						How I Work
					</SectionHeading>
				</ProcessHeading>
				<ProcessContent height={bounds.height}>
					{processAnimation}
				</ProcessContent>
			</StyledProcessSection>
		</SectionLight>
	);
};

export default ProcessSection;
