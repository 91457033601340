import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import respond from '../../styles/mediaQueries';

const StyledButtonLink = styled(Link)`
    display: inline-block;
    height: 6.4rem;
    line-height: 6.4rem;
    padding: 0 2.4rem;
    text-align: center;
    cursor: pointer;
    transition: all .2s;
    text-decoration: none;
    white-space: nowrap;
    border-radius: .2rem;
    font-weight: 500;

    ${respond('bp3')} {
        height: 5.6rem;
        line-height: 5.6rem;
    }

    ${respond('bp4')} {
        padding: 0 1.6rem;
        width: 100%;
    }
`;

const PrimaryButtonLink = styled(StyledButtonLink)`
    background-color: var(--bgSecondary);
    border: .3rem solid var(--bgSecondary);
    color: var(--textInverse) !important;


    &:hover {
        background-color: var(--bgPrimary);
        border: .3rem solid var(--bgSecondary);
        color: var(--text) !important;
        text-decoration: none;
    }
`;

const SecondaryButtonLink = styled(StyledButtonLink)`
    background-color: var(--white);
    border: .3rem solid var(--white);
    color: var(--black) !important;

    &:hover {
        background-color: var(--bgSecondary);
        border: .3rem solid var(--white);
        color: var(--textInverse) !important;
        text-decoration: none;
    }
`;

const ButtonLink = ({ to, secondary, children }) => {
	if (secondary) {
		return <SecondaryButtonLink to={to}>{children}</SecondaryButtonLink>;
	}

	return <PrimaryButtonLink to={to}>{children}</PrimaryButtonLink>;
};

export default ButtonLink;
