import React from 'react';

const DesignIcon = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'
			clipRule='evenodd'
			viewBox='0 0 101 91'>
			<g fill='none' stroke={fill} strokeWidth='3'>
				<path d='M27.766 13.495c.209-2.938.377-11.871 0-11.896a47.086 47.086 0 00-6.654.039c-.66.051-.66 9.646-.66 12.005' />
				<path d='M25.1 13.146c-8.786.004-13.114 5.486-13.296 12.243-.185 6.862 3.036 11.838 12.416 11.833 6.226-.003 12.47-4.975 12.426-11.833-.044-6.761-5.408-12.247-11.546-12.243z' />
				<path d='M14.16 33.583C12.622 39.044 3.266 82.006 3.526 82.218c.072.059 4.625 6.524 4.826 6.524.838 0 11.295-50.55 11.562-51.943m8.877-.462c2.736 12.735 11.077 52.735 11.257 52.403.788-1.45 6.042-5.926 5.911-6.522-.508-2.324-8.458-37.161-11.696-49.927' />
				<path d='M17.857 46.711c2.341.205 4.595.345 6.364.345 1.869 0 4.279-.157 6.76-.38m6.633-.56c4.025-.467 7.371-2.265 7.371-2.265l1.957 6.75s-3.368 1.614-7.784 2.081m-6.755.691c-2.891.243-6.01.433-8.182.433-2.069 0-5.024-.159-7.772-.384m-6.712-.692C5.111 52.248 1.5 50.601 1.5 50.601l1.958-6.75s3.547 1.827 7.744 2.308M42.765 68.228l56.64-47.071-1.174 65.912L17.169 89.5 36.63 73.327' />
				<path d='M86.529 46.665c-2.714 2.167-34.487 28.953-34.487 28.953l33.142-.798 1.345-28.155zm6.746-5.029c1.65.253 4.115.253 5.765 0m-5.4 11.785c1.312.098 3.894-.376 5.19 0m-6.026 11.455c.552-.021 4.849-.217 5.826-.217m-5.826 10.79c2.565-.207 3.059-.263 5.638-.223' />
			</g>
		</svg>
	);
};

export default DesignIcon;
