import React from 'react';
import styled from '@emotion/styled';

const SectionHeadingBase = styled('div')`
	display: grid;
	grid-template-rows: min-content .3rem min-content;
	width: 100%;
		
	& h5 {
		white-space: nowrap;
		margin-bottom: 0;
		color: ${props => props.color}
	}

    & div:last-of-type {
        background-color: ${props => props.color};
	}
`;

const StyledSectionHeading = styled(SectionHeadingBase)`
	grid-template-columns: min-content 1fr;

	& h5 {
		margin-right: 2.4rem;
		margin-bottom: 0;
	}

    & div:first-of-type {
	   grid-column: 1 / 2;
	   grid-row: 1 / 4;
    }

    & div:last-of-type {
    	grid-column: 2 / end;
	   	grid-row:  2 / 3;
    }

`;

const StyledSectionHeadingReverse = styled(SectionHeadingBase)`
	grid-template-columns:  1fr min-content;

	& h5 {
		margin-left: 2.4rem;
	}

    & div:first-of-type {
		text-align: right;
		grid-column: 2 / end;
	   	grid-row:  1 / 4;
    }

    & div:last-of-type {
   		grid-column: 1 / 2;
	   	grid-row: 2 / 3;
    }

`;

const SectionHeading = ({ reverse, color, children }) => {
	if (reverse) {
		return (
			<StyledSectionHeadingReverse color={color}>
				<div>
					<h5>{children}</h5>
				</div>
				<div />
			</StyledSectionHeadingReverse>
		);
	}

	return (
		<StyledSectionHeading color={color}>
			<div>
				<h5>{children}</h5>
			</div>
			<div />
		</StyledSectionHeading>
	);
};

export default SectionHeading;
