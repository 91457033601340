import React from 'react';
import styled from '@emotion/styled';

import SectionDark from '../shared/SectionDark';
import ButtonLink from '../shared/ButtonLink';
import HeroIllustration from '../svg/landing-illustrations/HeroIllustration';

import respond from '../../styles/mediaQueries';

const StyledHeroSection = styled('section')`
	margin-bottom: 16rem;

	${respond('bp4')} {
		margin-bottom: 8rem;
	}
`;

const Hero = styled('div')` 
		margin-top: 6.4rem;
		margin-bottom: 4rem;
		display: flex;
		height: 64rem;
		align-items: center;
		justify-content: space-between;

		${respond('bp3')} {
			margin-top: 1.6rem;
			margin-bottom: 2.4rem;
			flex-direction: column-reverse;
			height: auto;
		} 

		${respond('bp4')} {
			margin-top: 1.6rem;
			margin-bottom: 2.4rem;
		}
`;

const CTA = styled('div')` 
	margin-right: 8rem;

	${respond('bp3')} {
		margin-right: 0rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	& a {
		width: 100%;
	}

`;

const CTAText = styled('div')`
	max-width: 54.7rem;

	${respond('bp1')} {
		max-width: 50rem;
	}
	
	${respond('bp2')} {
		max-width: 44rem;
	}

	${respond('bp4')} {
		margin-bottom: 4rem;
	}

	& h1 {
		padding-bottom: 1.6rem;
		border-bottom: .3rem solid var(--textInverse2);
		${respond('bp3')} {
			text-align: center;
		}

	}

	& p {
		font-size: 2rem;
		line-height: 3.2rem;
		margin-bottom: 6.4rem;

		${respond('bp1')} {
			font-size: 2rem;
			line-height: inherit;
		}
		
		${respond('bp2')} {
			font-size: 1.9rem;
			text-align: center;
		}

		${respond('bp4')} {
			max-width: 28rem;
			text-align: center;
			margin: 0 auto;
		}
	}

`;

const StyledHeroImg = styled(`div`)`
	flex: 1 1 auto;
	display: flex;
	justify-content: flex-end;
	margin-right: -.2rem;
	margin-top: -4.8rem;

	${respond('bp3')} {
		justify-content: center;
		margin-right: 0rem;
		margin-top: 0rem;
		margin-bottom: 4rem;
	}

	${respond('bp4')} {
		width: 100%;
		margin-top: -3.2rem;
		margin-bottom: 2.4rem;
	}

	& svg {
		width: 100%;
		max-width: 50.4rem;
		height: intrinsic;

		${respond('bp4')} {
			max-width: 24rem;
		}

		& g {
			stroke-width: 2.8;

			${respond('bp2')} {
				stroke-width: 3.4;
			}

			${respond('bp3')} {
				stroke-width: 4;
			}

			${respond('bp4')} {
				stroke-width: 4.6;
			}
		}
	}
`;

const HeroSection = ({ children }) => {
	return (
		<StyledHeroSection>
			<SectionDark>
				<Hero>
					<CTA>
						<CTAText>
							<h1>
								Web&nbsp;Developer <br />in Kansas City
							</h1>
							<p>
								I fuse art and science to craft cool stuff for
								the web.
							</p>
						</CTAText>

						<ButtonLink to='/contact' secondary>
							Let's Craft Something Together
						</ButtonLink>
					</CTA>
					<StyledHeroImg>
						<HeroIllustration fill={'var(--textInverse2)'} />
					</StyledHeroImg>
				</Hero>
			</SectionDark>
		</StyledHeroSection>
	);
};

export default HeroSection;
