import React from 'react';
import styled from '@emotion/styled';

import respond from '../../styles/mediaQueries';

const StyledProcessNavigation = styled('nav')`
	grid-column: 1 / 2;
	grid-row: 1 / 3;
	border-right: .3rem solid var(--text2);
	padding: 4rem 5.4rem;

	${respond('bp3')} {
		grid-column: 1 / 1;
		grid-row: 2 / 3;
		border-right: none;
		border-bottom: .3rem solid var(--text2);
		padding: 4rem;
	}

	${respond('bp4')} {
		padding: 2.4rem;
	}


	& ul {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
		list-style-type: none;
		font-family: var(--fontHeader);
		margin-bottom: 0;

		${respond('bp3')} {
			flex-direction: row;
		}

		${respond('bp4')} {
			display: grid;
			grid-template-columns: repeat(3, 8.8rem);
			grid-gap: 2.4rem;
			margin: auto;
		}

		${respond('bp5')} {
			grid-template-columns: repeat(2, 8.8rem);
			grid-gap: 1.6rem;
		}

		& li {
			margin-bottom: 1.6rem;

			${respond('bp3')} {
				margin-bottom: 0;
			}
		}


		& li:last-of-type {
			margin-bottom: 0;

			& h6 {
				margin-bottom: 0;
			}
		}
	}
`;

const ActiveArrow = styled('span')`
	position: absolute;
	left: -1.6rem;
	top: .4rem;
	transform: rotate(90deg);
	width: 1.2rem;
	height: 1.2rem;

	${respond('bp3')} {
		width: 1.2rem;
		height: 1.2rem;
		top: .6rem;
		left: -1.6rem;
	}

	${respond('bp4')} {
		width: 1.2rem;
		height: 1.2rem;
		top: .6rem;
		left: -1.2rem;
	}
`;

const ProcessButton = styled('button')`
	position: relative;
	background-color: inherit;
	border: none;
	color: var(--text);
	cursor: pointer;
	font-size: inherit;
	font-family: inherit;
	font-weight:600;

	&:hover {
		text-decoration: underline;
	}

	&:focus {
		outline: none;
		box-shadow: none;
	}
`;

const ProcessNavigation = ({ current, setCurrent }) => {
	const activeIndicator = (
		<ActiveArrow>
			<svg
				viewBox='0 0 24 16'
				xmlns='http://www.w3.org/2000/svg'
				fillRule='evenodd'
				clipRule='evenodd'
				strokeLinejoin='round'
				strokeMiterlimit='2'>
				<path d='M11.999 0L24 16H0L11.999 0z' fill={'var(--text2)'} />
			</svg>
		</ActiveArrow>
	);

	return (
		<StyledProcessNavigation>
			<ul>
				<li>
					<ProcessButton onClick={() => setCurrent('Research')}>
						{current === 'Research' && activeIndicator}
						Research
					</ProcessButton>
				</li>
				<li>
					<ProcessButton onClick={() => setCurrent('Design')}>
						{current === 'Design' && activeIndicator}
						Design
					</ProcessButton>
				</li>
				<li>
					<ProcessButton onClick={() => setCurrent('Develop')}>
						{current === 'Develop' && activeIndicator}
						Develop
					</ProcessButton>
				</li>
				<li>
					<ProcessButton onClick={() => setCurrent('Test')}>
						{current === 'Test' && activeIndicator}
						Test
					</ProcessButton>
				</li>
				<li>
					<ProcessButton onClick={() => setCurrent('Deploy')}>
						{current === 'Deploy' && activeIndicator}
						Deploy
					</ProcessButton>
				</li>
				<li>
					<ProcessButton onClick={() => setCurrent('Repeat')}>
						{current === 'Repeat' && activeIndicator}
						Repeat
					</ProcessButton>
				</li>
			</ul>
		</StyledProcessNavigation>
	);
};

export default ProcessNavigation;
