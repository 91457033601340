import React from 'react';
import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

import respond from '../../styles/mediaQueries';

const StyledIllustration = styled(animated.div)`
    display: block;
    margin: 8rem auto;
    max-width: 28rem;

	${respond('bp1')} {
		margin: 6.4rem auto;
		max-width: 24rem;
	}

    ${respond('bp4')} {
        margin: 2.4rem auto;
        max-width: 20rem;
		& g {
			stroke-width: 3.5
		}
    }
`;

const Illustration = ({ children }) => {
	const [ ref, inView ] = useInView({
		triggerOnce: true,
		threshold: 0,
		rootMargin: '-40% 0%'
	});

	const props = useSpring({
		config: {
			mass: 1,
			tension: 100,
			friction: 30,
			velocity: -10
		},
		to: {
			opacity: inView ? 1 : 0
		}
	});

	return (
		<StyledIllustration ref={ref} style={props}>
			{children}
		</StyledIllustration>
	);
};

export default Illustration;
